import Head from 'next/head'
import Configs from '@/configs'
import { useRouter } from 'next/router'

const appUrl = Configs.appURL

const metaImgPath = `${appUrl}/social-thumbnail.jpg`
const metaTitle = 'Smart Storage & Worldwide Shipping'
const metaContent =
  'Store 1 Small box To 100 Large boxes Storage with Free Insurance up to $250 and 6 Months Free Storage. Get Smart Storage Services And ship anytime, anywhere!'
const metaKeywords = `Smart storage, Ship and storage, Storage and shipping, Storage facilities in New York, Storage facilities in Delaware, Storage and shipping services, Affordable storage, Cheapest storage, International storage and shipping, Storage and shipping services USA, Storage ez, Ez storage, Ez storage near me, Easy storage, Easy storage solutions, Easy storage near me, My storage near me, Self storage, Self storage near me, Self storage units near me, self storage facilities near me`

type ComponentProps = {
  title: string
  favIcon?: string
  keywords?: string
  description?: string
}
const Meta = ({ title, keywords, description, favIcon = `${appUrl}/favicons/favicon.ico` }: ComponentProps) => {
  const generatedTitle = title ? title : metaTitle
  const generatedContent = description ? description : metaContent
  const generatedKeywords = keywords ? keywords : metaKeywords

  const router = useRouter()
  const asPath = router.asPath === '/' ? '' : router.asPath
  const canonicalUrl = `${appUrl}${asPath}`

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
        <meta name='robots' content='index, follow' />
        <meta name='language' content='English' />

        <title>{`${generatedTitle}: ${Configs.APP_NAME}`}</title>
        <meta name='description' content={`${description}`} />
        <meta name='keywords' content={`${generatedKeywords}`} />

        <link rel='icon' href={`${favIcon}`} />
        <link rel='canonical' href={canonicalUrl} />
        <link rel='apple-touch-icon' sizes='180x180' href={`${appUrl}/favicons/apple-touch-icon.png`} />
        <link rel='icon' type='image/png' sizes='32x32' href={`${appUrl}/favicons/favicon-32x32.png`} />
        <link rel='icon' type='image/png' sizes='16x16' href={`${appUrl}/favicons/favicon-16x16.png`} />
        <link rel='manifest' href={`${appUrl}/favicons/site.webmanifest`} />

        {/*FB META Tags*/}

        <meta property='og:title' content={generatedTitle} />
        <meta property='og:description' content={generatedContent} />
        <meta property='og:image' content={metaImgPath} />
        <meta property='og:url' content={appUrl} />
        <meta property='og:site_name' content={Configs.APP_NAME} />

        {/*Twitter META Tags*/}

        <meta name='twitter:title' content={generatedTitle} />
        <meta name='twitter:description' content={generatedContent} />
        <meta name='twitter:image' content={metaImgPath} />
        <meta name='twitter:card' content={generatedContent} />
        <meta name='twitter:image:alt' content={Configs.APP_NAME} />
        <meta name='twitter:site' content={`@${Configs.APP_NAME}`} />
      </Head>
    </>
  )
}

export default Meta
